import { AfterViewInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { CrudService } from './service/crud.service';
import { JsserviceService } from './service/jsservice.service';
import { NotificationService } from './shared/notification';
// import { ToastrService } from 'ngx-toastr';

declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // title = 'Mem';
  constructor(public translate: TranslateService,public crud:CrudService,public notifyservice:NotificationService,public jsservice:JsserviceService) {
    translate.setDefaultLang('fr');
    translate.use('fr');
  }
  ngOnInit(): void { 
        this.crud.console();
        this.jsservice.popover();
       
  } 

  

  // ngAfterViewInit() {
  //   this.notifyservice.onSuccess('Bienvenue Kenza');
  // }

}

