import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  fullUrl = environment.apiUrl;
  $id=3;
  currentuser;
  reseturl=environment.api_url;
  auth_token;

  constructor(private http: HttpClient,public translate:TranslateService, public router: Router) { }
  //Methode Http
  post(hote: string, gert: any) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote;
    //const obj = JSON.parse(JSON.stringify(gert));
   

    return this.http.post(url, gert,$request);
  }
  resetemail(hote: string) {
    const url = this.reseturl + hote;
    return this.http.get(url);
  }

  postfile(hote: string, gert: any,id) {
    const url = this.fullUrl + hote+id;
    //const obj = JSON.parse(JSON.stringify(gert));
   

    return this.http.post(url, gert);
  }


  put(hote: string, gert: any, id) {
    const url = this.fullUrl + hote + id;
    //const obj = JSON.parse(JSON.stringify(gert));
    return this.http.put(url, gert);
  }

  delete(hote: string, $id) {
    const url = this.fullUrl + hote + $id;
    return this.http.delete(url);
  }
  get(hote: string) {
    const $request=this.gettokenparams(localStorage.getItem('access_token'));
    const url = this.fullUrl + hote;
     

      return this.http.get(url,$request);
  }
  getById(hote: string, $id) {
    const url = this.fullUrl + hote + $id;
    return this.http.get(url);
  }
    //search name
search(hote:string,formData : string) {
  const url = this.fullUrl + hote + formData;
    //const obj = JSON.parse(JSON.stringify(formData));
    return this.http.get(url);
 
}
getTranslation(key: string): Observable<any> {
  return this.translate.get(key);
}
console() {
  console.clear();
  this.getTranslation('KONSOLE').subscribe(
    (translation) => {
      console.log("%c" + translation, "color: red; font-size: x-large");
    }
  );

  this.getTranslation('KonsoleDescription').subscribe(
    (translation) => {
      console.log("%c" + translation, "color: black; font-size: 20px");
    }
  );
}
getAnnee(){
  return ['2021','2022','2023'];
}
getMois(){
  return [{'id':1,'value':'Janvier'},{'id':2,'value':'Fevrier'},{'id':3,'value':'Mars'},{'id':4,'value':'Avril'},{'id':5,'value':'Mai'},{'id':6,'value':'Juin'},
  {'id':7,'value':'Juillet'},{'id':8,'value':'Aout'},{'id':9,'value':'Septembre'},{'id':10,'value':'Octobre'},{'id':11,'value':'Novembre'},{'id':12,'value':'Decembre'}];
}
getcolordash(){
  return ['#2980B9', '#34495E', '#3498DB', '#273746', '#7FB3D5', '#717D7E', '#1A5276', '#AAB7B8', '#7FB3D5', '#A6ACAF', '#85929E'];
}

getcurrentuser(){
  var $user={'nom':localStorage.getItem('nom'),'prenom':localStorage.getItem('prenom'),
  'sexe':localStorage.getItem('sexe'),'photo':localStorage.getItem('photo'),'auth':localStorage.getItem('iduser'),
  'type_compte':localStorage.getItem('type_compte')};
  return $user;
  //return this.currentuser;
}
//Login
  setcurrentuser($data){
    this.currentuser=$data;
  }
  getToken() {
    return localStorage.getItem('access_token');
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }
  doLogout() {
    let removeToken = localStorage.removeItem('access_token');
    let removeUsers = localStorage.removeItem('users');
    localStorage.removeItem('nom');
    localStorage.removeItem('type_compte');
    if (removeToken == null && removeUsers == null ) {
      this.router.navigate(['users/login']);
    }
    this.get('/logout');
  }
 
  setlocalstorage($data){
    localStorage.setItem('access_token',$data.access_token);
    localStorage.setItem('prenom',$data.user.prenom);
    localStorage.setItem('nom',$data.user.nom);
    localStorage.setItem('sexe',$data.user.sexe);
    localStorage.setItem('photo',$data.user.photo);
    localStorage.setItem('type_compte',$data.user.type_compte);
    localStorage.setItem('iduser',$data.user.id_inscription);
  }
  // getlocalstorage(){
  //   return localStorage.getItem('users');
  // }
  gettokenparams($token){
    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${$token}`
    });

   const requestOptions = { headers: headers };
   return requestOptions;
  }

}


