import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
// import { ToastrModule } from 'ngx-toastr';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppRoutingModule } from './/app-routing.module';
import { NotifierModule } from 'angular-notifier';
import { ToastDefaults, SnotifyService, SnotifyModule } from 'ng-snotify';
import { AccountGuard } from './service/account/accountGuard.service';
import { BaseService } from './service/base.service';
import { EtablissementService } from './service/etablissement.service';
import { PageService } from './service/page.service';
import { UserService } from './service/account/user.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from './shared/notification';



 
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NotifierModule,    
    // ToastrModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    NotifierModule.withConfig({
      position: {

        horizontal: {

          /**
           * Defines the horizontal position on the screen
           * @type {'left' | 'middle' | 'right'}
           */
          position: 'right',

          /**
           * Defines the horizontal distance to the screen edge (in px)
           * @type {number} 
           */
          distance: 12

        },

        vertical: {

          /**
           * Defines the vertical position on the screen
           * @type {'top' | 'bottom'}
           */
          position: 'top',

          /**
           * Defines the vertical distance to the screen edge (in px)
           * @type {number} 
           */
          distance: 12
        }

      }
    }),
    SnotifyModule.forRoot(),
    AppRoutingModule,
    NgbModule
  
  ],
 

  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    AccountGuard,
    BaseService,
    EtablissementService,
    PageService,
    UserService,
    NotificationService
      ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
 
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
