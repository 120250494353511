import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
// import { ToastrService } from 'ngx-toastr';
import {SnotifyService} from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { AppInjector } from './injector';


declare var jquery: any;
declare var $: any;
declare var jqueryfademenu;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private http: HttpClient;
  private notificationService: NotifierService;
  private snotify: SnotifyService;
  private translate: TranslateService;
  //notification
  private readonly notification: NotifierService;

  private baseUrl: string = 'http://localhost:8000/api/';
  // private baseUrl: string = 'http://open2sw.com/muntuDemo/muntu/';

  //tell if data were already initialized
  private tokenAlreadyInitialized = true;
  private loading = false;

  private token = null;

  private alreadyTranslated = false;

  private showFooter = true;
  showFooterSubject = new Subject<boolean>();
  menuInitialized=false;
  // public toastrService:ToastrService;

  constructor() {
    const injector = AppInjector.getInjector();
    this.http = injector.get(HttpClient);
    this.notification = injector.get(NotifierService);
    this.snotify = injector.get(SnotifyService);
    this.translate = injector.get(TranslateService);

    //translating
    if (!this.alreadyTranslated) {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.alreadyTranslated = true;
      // this.console();
    }
    // if(!this.menuInitialized){
    //   this.menu();
    // }
  }

  // TRANSALATING METHODS
  hasBeenTranlated(): boolean {
    return this.alreadyTranslated;
  }

  getBaseUrl() {
    return this.baseUrl;
  }


  //CHANGE HttpHeaders depending of current token validity
  getHttpOptions(): {} {
    if (!this.tokenAlreadyInitialized) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': this.token
        })
      };
    }
    else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })
      };
    }
  }

  /*************
  * HTTP METHODS
  **************/
  post(hote: string, param: {} = {}) {
    const url = this.baseUrl + hote;
    return this.http.post(url, param);
  }

  put(hote: string, param: {} = {}) {
    const url = this.baseUrl + hote;
    return this.http.put(url, param);
  }

  delete(hote: string) {
    const url = this.baseUrl + hote;
    return this.http.delete(url);
  }

  get(hote: string) {
    let url = this.baseUrl + hote;
    return this.http.get(url);
  }

  postFile(hote: string, formdata) {

    const url = this.baseUrl + hote;
    return this.http.post(url, formdata);
  }

  //create notification
  create_notification(type = "info", message) {
    this.notification.notify(type, message);
  }
  //create notifiaction depending of translate file
  notify(type = "info", key: string) {
    this.translate.get(key).subscribe(
      message => this.notification.notify(type, message)
    )
  }

  //create notification
  create_snotify(type:string, message:string) {
    this.snotify[type](message,  {
      timeout: 2000,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true
    });
  }

  // hide oldest notification
  hide_notification(){
    this.notification.hideOldest();
  }

  // translate an element
  getTranslation(key: string): Observable<any> {
    return this.translate.get(key);
  }

  isLoading(): boolean {
    return this.loading;
  }

  setLoading(state: boolean) {
    this.loading = state;
  }



  // PRINT MESSAGE TO CONSOLE
  console() {
    console.clear();
    this.getTranslation('KONSOLE').subscribe(
      (translation) => {
        console.log("%c" + translation, "color: red; font-size: x-large");
      }
    );

    this.getTranslation('KonsoleDescription').subscribe(
      (translation) => {
        console.log("%c" + translation, "color: black; font-size: 20px");
      }
    );
  }

  // allow dropdown menu
  menu(){
    jqueryfademenu.buildmenu("fade-menu");
    $('#fade-menu').on('click', 'a[href="#"]', function(e) {
        e.preventDefault();
    });
    this.menuInitialized = true;
  }

  // footer
  emitFooter() {
    return this.showFooterSubject.next(this.showFooter);
  }

  toggleFooter(state: boolean) {
    this.showFooter = state;
    this.emitFooter();
  }
}
