import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyauthGuard } from './service/myauth.guard';



const routes: Routes = [
  {  
    path: 'users',loadChildren: ()=> import('./usersgest/usersgest.module').then(m=>m.UsersgestModule)
  },
  {
    path: '',
    // canActivate: [MyauthGuard],
    loadChildren: () => import('./sidebarapp/sidebarapp.module').then( m => m.SidebarappModule)
  },
  {  
    path: '**',loadChildren: ()=> import('./not-found/not-found.module').then(m=>m.NotFoundModule)
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
